
  import * as Sentry from '@sentry/browser'

  export default {
    name: `RedirectPage`,
    options: {
      auth: false
    },
    mounted() {
      if (this.isLoggedAndIsSameCompany()) {
        this.redirectToRoute()
      } else {
        this.setRouteInState()
        this.redirectToAuth2WithScope()
      }
    },
    methods: {
      isLoggedAndIsSameCompany() {
        return this.isLogged() && this.isSameCompany()
      },
      isLogged() {
        return this.$auth.loggedIn
      },
      isSameCompany() {
        return this.$route?.query?.company ? this.$route.query.company === this.$auth?.user?.current_company : false
      },
      isRouteExiste() {
        return this.$route.query?.route
      },
      redirectToRoute() {
        if (this.isRouteExiste()) {
          this.$router.push({ path: this.$route.query.route })
        }
      },
      setRouteInState() {
        if (this.isRouteExiste()) {
          this.$store.commit(`setRedirect`, this.$route.query.route)
        }
      },
      redirectToAuth2WithScope() {
        try {
          // http://localhost:3000/redirect/?route=%2Fscreenings/0418022a-25c5-4026-9902-71aaa637923f&company=banque_ebezner
          this.$store.dispatch(`companies/changeCompany`, this.$route.query.company)
        } catch (e) {
          console.error(`error`, e)
          this.setError(e)
        }
      },
      setError(e) {
        Sentry.captureException(e)
        this.$flashMessage.error({
          title: this.$t(`messages.error`),
          message: this.$t(`messages.unknown_error`)
        })
      }
    }
  }
